import { configureStore } from '@reduxjs/toolkit'
import userStatusReducer from './features/userStatusSlice/userStatusSlice'
import CatTransportistasSlice from './features/CatTransportistasSlice'


const store = configureStore({
  reducer: {
    userStatus: userStatusReducer,
    CatTransportistasSlice: CatTransportistasSlice
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store