import http from '../../../Services/common/http-common'
import { DTOFacturaDetail } from '../DTO/DTOFacturaDetail'
import DTOProgCruce from '../DTO/DTOProgCruce'
import DTORptCruces from '../DTO/DTORptCruces'
import DTORptFacturas from '../DTO/DTORptFacturas'
import IFacturasHeader from '../Interfaces/IFacturasHeader'

class CHeinekenDataService {
  GetFacturasTxt(Inicio: string, Fin: string) {
    return http.get<IFacturasHeader[]>(
      `/Reportes/RptFacturasTxt?Inicio=${Inicio}&Fin=${Fin}`
    )
  }
  GetProgramacionCruces() {
    return http.get<DTOProgCruce[]>(`/Reportes/RptProgramacionCruces`)
  }
  GetFacturas(Inicio: string, Fin: string) {
    return http.get<DTORptFacturas[]>(
      `/Reportes/RptFacturas?Inicio=${Inicio}&Fin=${Fin}`
    )
  }

  GetFacturaDetail(idHeader: number){
    return http.get<DTOFacturaDetail[]>(`/Reportes/FacturaDetail?IdHeader=${idHeader}`)
  }

  GetCruces(Inicio: string, Fin: string) {
    return http.get<DTORptCruces[]>(
      `/Reportes/RptCruces?Inicio=${Inicio}&Fin=${Fin}`
    )
  }

  GetBL(id: number) {
    http.defaults.baseURL = process.env.REACT_APP_SERVICES
    return http
      .get<ArrayBuffer>(`heineken/services/BOL2PDF?id=${id}`, {
        responseType: 'arraybuffer',
      })
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  AppendFolioCartaPorte(id: number, FolioCartaPorte: string, SCAC: string, CAAT: string, TransportistaAmericano: boolean) {
    return http.put<DTOProgCruce>(
      `/Reportes/AppendFolioCartaPorte/${id}`,
      {FolioCartaPorte, SCAC, CAAT, TransportistaAmericano}
    )
  }

  AppendShipmentsXLSX(formData: FormData){
    return http.post(`/Reportes/AppendShipmentsXLSX`,formData,{
        headers:{
            "Content-Type":'multipart/form-data'
        }
    })
  }

  UpdateCaja(dto: DTORptFacturas){
    return http.put(`/Reportes/UpdateCaja/${dto.id}?Caja=${dto.caja}`)
  
  }
  UpdateShipment(dto: DTORptFacturas | DTORptCruces | DTOProgCruce){
    return http.put(`/Reportes/AppendShipment/${dto.factura}?Shipment=${dto.shipment}`)
  }

  UpdateFechasCompromiso(id: number, FechaEstimadaCruce: string, FechaCita: string){
    return http.put(`/Reportes/UpdateFechasCompromiso/${id}?FechaEstimadaCruce=${FechaEstimadaCruce}&FechaCita=${FechaCita}`)
  }

  AppendFacturaTarimas(fileId: number, caja: string, transportista: string){
    return http.post<DTORptFacturas>(`/Reportes/AppendFacturaTarimas?fileId=${fileId}&caja=${caja}&transportista=${transportista}`)
  }

  UpdateFacturaDetail(detail: DTOFacturaDetail){
    return http.post<DTOFacturaDetail>(`/Reportes/UpdateDetail`, detail)
  }
}
export default new CHeinekenDataService()
