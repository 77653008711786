import { useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Form, Modal, ModalBody, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap'
import { BsFileEarmarkPdfFill, BsFilePdf, BsFiletypeXml, BsSearch, BsUpload } from 'react-icons/bs'
import {FaCircleCheck, FaCircleXmark, FaEye, FaFileCirclePlus, FaFileExcel} from 'react-icons/fa6'
import '../../../css/masterDetail.css'
import '../../../css/generalStyles.css'
import DSHeineken from '../Services/Heineken.Services'
import DataTable from 'react-data-table-component'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import xlsx from 'json-as-xlsx'
import DTORptFacturas from '../DTO/DTORptFacturas'
import { AxiosError } from 'axios'
import { MFileUploader } from '../../Utils/MFileUploader/MFileUploader'
import { IconContext } from 'react-icons'
import FileManagerServices from '../../../Services/Utils/FileManager.Services'
import { AiFillSave } from 'react-icons/ai'
import FileManager from '../../Utils/FileManager/FileManager'
import CatTransportistasServices from '../Services/CatTransportistas.Services'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { populateCatTransportistas } from '../../../store/features/CatTransportistasSlice'
import { RptFacturasDetail } from './RptFacturasDetail'

export interface IFtpFacturasProps {}

export default function FtpFacturas(props: IFtpFacturasProps) {
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Perfil, setPerfil] = useState(() => {
    const stickyValue = window.localStorage.getItem('Perfil')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const dispatch = useDispatch()
  const mCatTransportistas = useSelector(
    (state: RootState) => state.CatTransportistasSlice.CatTransportistas
  )
  const [Data, setData] = useState<DTORptFacturas[]>([])
  const [DataOriginal, setDataOriginal] = useState<DTORptFacturas[]>([])
  const [Inicio, setInicio] = useState(currentDate(-30))
  const [Fin, setFin] = useState(currentDate(0))
  const [filtro, setFiltro] = useState('')
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')
  const [ShowSelectionModal, setShowSelectionModal] = useState(false)
  const [BackgroundColorL, setBackgroudColorL] = useState('')
  const [BackgroundColorR, setBackgroudColorR] = useState('')
  //
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);
  const hiddenInvoiceInputRef = useRef<HTMLInputElement>(null);
  const [File, setFile] = useState<File | null>();
  const [showSpinner, setShowSpinner] = useState(false)
  const [showMultiFileUploader, setShowMultiFileUploader] = useState(false)
  const [FileType, setFileType] = useState('')
  const [showTarimasModal, setShowTarimasModal] = useState(false)
  const [FileId, setFileId] = useState(0)
  const [ProcessId, setProcessId] = useState(0)
  const [Caja, setCaja] = useState('')
  const [Transportista, setTransportista] = useState('')
  const [showFileReplacementModal, setShowFileReplacementModal] = useState(false)
  const colData = [
    {
      name: 'id',
      width: '100px',
      selector: (row: DTORptFacturas) => row.id,
      sortable: true,
    },
    {
      name: 'Prefile',
      center: true,
      width: '80px',
      cell: (row: DTORptFacturas) => (
        <OverlayTrigger
          placement='top'
          overlay={ row.fechaPrefile ? <Tooltip id="button-tooltip-2">{row.fechaPrefile}</Tooltip> : <br style={{display:'none'}}/>}
        >
        <div style={
          row.fechaPrefile === '' ? {
            pointerEvents:'none',
            opacity: 0.5
          } : {
            
          }
        }>
          <IconContext.Provider value={{ color: 'red', size: '17px' }}>
            <BsFileEarmarkPdfFill 
              onClick={() => {
                Perfil === "Administrador" || Perfil === "GEMCO" ?
                openFileReplacementModal(row.id, 32) :
                GetPrefile(row.factura, row.factura2)
              }}
              style={{ cursor: 'pointer' }}
            />
          </IconContext.Provider>
        </div>
        </OverlayTrigger>
      ),
    },
    {
      name: 'Doda',
      center: true,
      width: '80px',
      cell: (row: DTORptFacturas) => (
        <OverlayTrigger
          placement='top'
          overlay={ row.fechaDoda ? <Tooltip id="button-tooltip-2">{row.fechaDoda}</Tooltip> : <br style={{display:'none'}}/>}
        >
          <div 
            style={
              row.fechaDoda === '' ? {
                pointerEvents:'none',
                opacity: 0.5
              } : {
                
              }
            }
          >
            <IconContext.Provider value={{ color: 'red', size: '17px' }}>
              <BsFileEarmarkPdfFill 
              onClick={() => {
                Perfil === "Administrador" || Perfil === "GEMCO" ?
                openFileReplacementModal(row.id, 34) :
                GetDoda(row.id)
              }}
              style={{ cursor: 'pointer' }}/>
            </IconContext.Provider>
          </div>
        </OverlayTrigger>
      ),
    },
    {
      name: 'BL',
      center: true,
      width: '60px',
      cell: (row: DTORptFacturas) => (
        <div
          onClick={() => {
            ShowBL2PDF(row.id)
          }}
          style={{ cursor: 'pointer' }}
        >
          <IconContext.Provider value={{ color: 'red', size: '17px' }}>
            <BsFileEarmarkPdfFill />
          </IconContext.Provider>
        </div>
      ),
    },
    {
      name: 'Folio Carta Porte',
      width: '300px',
      //selector: (row: DTORptFacturas) => row.folioCartaPorte,
      cell: (row: DTORptFacturas) => (
        <OverlayTrigger
          placement='top'
          overlay={ row.fechaHoraFCP !== '' ? <Tooltip id="button-tooltip-2">{row.fechaHoraFCP}</Tooltip> : <br style={{display:'none'}}/>}
        >
          <span style={{ cursor: 'pointer' }}>{row.folioCartaPorte}</span>
        </OverlayTrigger>
      ),
      sortable: true,
    },
    {
      name: 'SCAC',
      width: '100px',
      selector: (row: DTORptFacturas) => row.scac,
      sortable: true,
    },
    {
      name: 'CAAT',
      width: '100px',
      selector: (row: DTORptFacturas) => row.caat,
      sortable: true,
    },
    {
      name: 'Transportista Americano',
      width: '200px',
      cell: (row: DTORptFacturas) => (
        <div
          style={{
            alignItems:'center',
            textAlign: 'center',
            cursor: 'pointer',
            width:'100%'
          }}
        >
          <IconContext.Provider value={{ color: row.transporteAmericano ? 'green' : 'red', size: '18px' }}>
            {row.transporteAmericano ? <FaCircleCheck/> : <FaCircleXmark/>}
          </IconContext.Provider>
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Trafico',
      width: '100px',
      selector: (row: DTORptFacturas) => row.trafico,
      sortable: true,
    },
    {
      name: 'Fecha Estimada Cruce',
      width: '200px',
      selector: (row: DTORptFacturas) => row.fechaEstimadaCruce,
      sortable: true,
    },
    {
      name: 'Fecha Cita',
      width: '150px',
      selector: (row: DTORptFacturas) => row.fechaCita,
      sortable: true,
    },
    {
      name: 'Proveedor',
      width: '200px',
      selector: (row: DTORptFacturas) => row.proveedor,
      sortable: true,
    },
    {
      name: 'Transportista',
      width: '200px',
      selector: (row: DTORptFacturas) => row.transportista,
      sortable: true,
    },
    {
      name: 'Factura',
      selector: (row: DTORptFacturas) => row.factura,
      sortable: true,
    },
    {
      name: 'Factura 2',
      selector: (row: DTORptFacturas) => row.factura2,
      sortable: true,
    },
    {
      name: 'Pedido',
      selector: (row: DTORptFacturas) => row.pedido,
      sortable: true,
    },
    {
      name: 'Pedido SAP',
      selector: (row: DTORptFacturas) => row.pedidoSAP,
      sortable: true,
    },
    {
      name: 'Delivery',
      selector: (row: DTORptFacturas) => row.delivery,
      sortable: true,
    },
    /* {
      name: '',
      width: '50px',
      omit: Perfil !== "Administrador" && Perfil !== "GEMCO",
      //selector: (row: DTORptCruces) => row.shipment,
      cell: (row: DTORptFacturas) => {
        return (
          <IconContext.Provider value={{ color: 'blue', size: '18px' }}>
            <FaEye onClick={() => {openShipmentsView(row.pedimento)}} style={{cursor:'pointer'}}/>
          </IconContext.Provider>
        )
      },
      sortable: true,
    }, */
    {
      name: 'Shipment',
      width: '150px',
      // selector: (row: DTORptCruces) => row.shipment,
      cell: (row: DTORptFacturas) => {
        return (
          Perfil === "Administrador" || Perfil === "GEMCO" || Perfil === 'CLIENTE: HEINEKEN' ?
          <Form.Control
            type='text'
            defaultValue={row.shipment}
            onChange={
              (e) => {
                row.shipment = e.target.value
              }
            }
          /> : row.shipment
        )
      },
      sortable: true,
    },
    {
      name: '',
      center: true,
      width: '80px',
      omit: Perfil !== "Administrador" && Perfil !== "GEMCO" && Perfil !== 'CLIENTE: HEINEKEN',
      cell: (row: DTORptFacturas) => (
        <OverlayTrigger
          placement='top'
          overlay={ <Tooltip id="button-tooltip-3">Guardar</Tooltip>}
        >
            <IconContext.Provider value={{ color: '#0d6efd', size: '35px' }}>
              <AiFillSave
                onClick={() => {
                  updateShipment(row)
                }}
              style={{ cursor: 'pointer' }}/>
            </IconContext.Provider>
        </OverlayTrigger>
      ),
    },
    {
      name: 'Destino',
      selector: (row: DTORptFacturas) => row.destino,
      sortable: true,
    },
    {
      name: 'Fecha',
      selector: (row: DTORptFacturas) => row.fechaFactura,
      sortable: true,
    },
    {
      name: 'Aduana',
      selector: (row: DTORptFacturas) => row.aduana,
      sortable: true,
    },
    {
      name: 'Patente',
      selector: (row: DTORptFacturas) => row.patente,
      sortable: true,
    },
    {
      name: 'Pedimento',
      selector: (row: DTORptFacturas) => row.pedimento,
      sortable: true,
    },
    {
      name: 'Caja',
      width: '180px',
      /* selector: (row: DTORptFacturas) => row.caja,
      sortable: true, */
      cell: (row: DTORptFacturas) => {
        return (
          <Form.Control
            type='text'
            defaultValue={row.caja}
            onChange={
              (e) => {
                row.caja = e.target.value
              }
            }
          />
        )
      }
    },
    {
      name: '',
      center: true,
      width: '80px',
      cell: (row: DTORptFacturas) => (
        <OverlayTrigger
          placement='top'
          overlay={ <Tooltip id="button-tooltip-3">Guardar</Tooltip>}
        >
            <IconContext.Provider value={{ color: '#0d6efd', size: '35px' }}>
              <AiFillSave
                onClick={() => {
                  updateCaja(row)
                }}
              style={{ cursor: 'pointer' }}/>
            </IconContext.Provider>
        </OverlayTrigger>
      ),
    },
    {
      name: 'Bultos',
      selector: (row: DTORptFacturas) => row.bultos,
      sortable: true,
    },
  ]

  const updateCaja = (dto: DTORptFacturas) => {
    DSHeineken.UpdateCaja(dto)
    .then(resp => {
      showToastMessage('Informativo', 'Número de caja actualizado con éxito')
      return
    })
    .catch(() => {
      showToastMessage('Error', 'Ocurrió un error, favor de intentarlo nuevamente')
      return
    })
  }

  function currentDate(days: number): string {
    var today = new Date()
    today.setDate(today.getDate() + days)
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0')
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  const loadReport = (Inicio: string, Fin: string) => {
    DSHeineken.GetFacturas(Inicio, Fin)
      .then((response) => {
        setData(response.data)
        setDataOriginal(response.data)
      })
      .catch((e: Error) => {
        showToastMessage('Error', 'Ocurrio un error' + e.message)
        return
      })
  }

  const ShowBL2PDF = (id: number) => {
    const newWindow = window.open(
      process.env.REACT_APP_SERVICES +
        'heineken/services/BOL2PDF' +
        '?id=' +
        id,
      '_blank',
      'noopener,noreferrer'
    )
    if (newWindow) newWindow.opener = null
  }

  const GetPrefile = (Factura: string, Factura2: string) => {
    FileManagerServices.getPrefile(Factura, Factura2.substring(2))
      .then((response: any) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        }
      })
      .catch((e: Error) => {
        showToastMessage('Error', 'Prefile no existe')
        return
      })
  }

  useEffect(() => {
    CatTransportistasServices.GetCatTransportistas()
      .then((response) => {
        dispatch(populateCatTransportistas(response.data))
      })
      .catch((e: Error) => {
        showToastMessage('Error', 'Ocurrio un error: ' + e)
        return
      })
    loadReport(Inicio, Fin)
  }, [])

  const filtraReporte = (e: any) => {
    setFiltro(e.target.value)
    if (e.target.value.length > 0) {
      setData(
        DataOriginal.filter(function (row) {
          return (
            row.fechaFactura
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.factura
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.proveedor
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.destino
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.pedidoSAP
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.caja
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.transportista
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.aduana
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.patente
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.pedimento
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.trafico
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) 
          )
        })
      )
    } else {
      setData(DataOriginal)
    }
  }

  function exportExcel(jsonData: DTORptFacturas[], fileName: string): void {
    let Heading = [
      [
        'Proveedor',
        'Factura',
        'Pedido',
        'Pedido SAP',
        'Delivery',
        'Shipment',
        'Destino',
        'Fecha de factura',
        'Aduana',
        'Patente',
        'Pedimento',
        'Caja',
        'Bultos',
      ],
    ]
    const dataOnly = jsonData.map(
      ({
        proveedor,
        factura,
        pedido,
        pedidoSAP,
        delivery,
        shipment,
        destino,
        fechaFactura,
        aduana,
        patente,
        pedimento,
        caja,
        bultos,
      }) => {
        return {
          proveedor,
          factura,
          pedido,
          pedidoSAP,
          delivery: delivery.substring(2),
          shipment,
          destino,
          fechaFactura: { fechaFactura },
          aduana,
          patente,
          pedimento,
          caja,
          bultos,
        }
      }
    )
    let data = [
      {
        sheet: 'Facturas',
        columns: [
          { label: 'Proveedor', value: 'proveedor' },
          { label: 'Factura', value: 'factura' },
          { label: 'Pedido', value: 'pedido' },
          { label: 'Pedido SAP', value: 'pedidoSAP' },
          { label: 'Delivery', value: 'delivery' },
          { label: 'Shipment', value: 'shipment' },
          { label: 'Destino', value: 'destino' },
          {
            label: 'Fecha',
            value: (row: any) =>
              row.fechaFactura.fechaFactura
                ? row.fechaFactura.fechaFactura.substring(0, 10)
                : '',
          },
          { label: 'Aduana', value: 'aduana' },
          { label: 'Patente', value: 'patente' },
          { label: 'Pedimento', value: 'fechaEnvio' },
          { label: 'Caja', value: 'caja' },
          { label: 'Bultos', value: 'bultos' },
        ],
        content: dataOnly,
      },
    ]
    let settings = {
      fileName,
      extraLength: 3,
      writeOptions: {},
      RTL: false,
    }
    xlsx(data, settings)
  }

  const selectDownload = () => {
    if(Data.length === 0){
      showToastMessage('Informativo', 'Sin facturas para descargar')
      return
    }
    setShowSelectionModal(true)
  }

  const downloadExcel = () => {
    exportExcel(Data, 'Reporte de Facturas')
    setShowSelectionModal(false)
  }

  const downloadEmptyShipmentRecords = () => {
    let filteredData = Data.filter(x => x.shipment === '')
    exportExcel(filteredData, 'Reporte de Facturas')
    setShowSelectionModal(false)
  }

  const selectFile = () => {
    hiddenFileInputRef.current?.click()
  }

  const openInvoiceSelection = () => {
    hiddenInvoiceInputRef.current?.click()
  }

  const uploadFile = (file: File) => {
    setShowSpinner(true)
    const formData = new FormData()
    formData.append('name', file.name)
    formData.append('file', file)
    DSHeineken.AppendShipmentsXLSX(formData)
    .then((res) => {
        hiddenFileInputRef.current!.value = ''
        setShowSpinner(false)
        showToastMessage('Informativo', "Shipments actualizados correctamente")
        loadReport(Inicio, Fin)
        return
    })
    .catch((err: AxiosError) => {
        setShowSpinner(false)
        hiddenFileInputRef.current!.value = ''
        showToastMessage('Error', err.request.responseText)
        return
    })
  }

  const uploadInvoice = (file: File) => {
    setShowSpinner(true)
    const formData = new FormData()
    formData.append('name', file.name)
    formData.append('file', file)
    FileManagerServices.AppendInvoice(formData, UserId)
    .then((res) => {
        hiddenInvoiceInputRef.current!.value = ''
        setShowSpinner(false)
        showToastMessage('Informativo', "Factura dada de alta con éxito")
        loadReport(Inicio, Fin)
        return
    })
    .catch((err: AxiosError) => {
        setShowSpinner(false)
        hiddenInvoiceInputRef.current!.value = ''
        showToastMessage('Error', err.request.responseText)
        return
    })
  }

  const openFileUpload = (fileType: string) => {
    setFileType(fileType)
    setShowMultiFileUploader(true)
  }

  const uploadPrefiles = (formData: FormData) => {
    FileManagerServices.AppendPrefiles(formData, UserId)
    .then((resp) => {
      setShowMultiFileUploader(false)
      showToastMessage('Informativo', 'Prefiles cargados correctamente')
      loadReport(Inicio, Fin)
      return
    }).catch(() => {
      setShowMultiFileUploader(false)
      showToastMessage('Error', 'Ocurrió un error al cargar los prefiles. Favor de intentarlo nuevamente')
      return
    })
    
  }

  const uploadDodas = (formData: FormData) => {
    FileManagerServices.AppendDodas(formData, UserId)
    .then((resp) => {
      setShowMultiFileUploader(false)
      showToastMessage('Informativo', 'Dodas cargados correctamente')
      loadReport(Inicio, Fin)
      return
    }).catch(() => {
      setShowMultiFileUploader(false)
      showToastMessage('Error', 'Ocurrió un error al cargar los Dodas. Favor de intentarlo nuevamente')
      return
    })
  }
  const GetDoda = (id: number) => {
    FileManagerServices.getFileContent(id, 34)
    .then((response: any) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        window.open(url)
      }
    })
    .catch((e: Error) => {
      showToastMessage('Error', 'Doda no existe')
      return
    })
  }

  const updateShipment = (dto: DTORptFacturas) => {
    if(dto.shipment === ''){
      showToastMessage('Error', 'El shipment no puede ir en blanco')
      return
    }
    DSHeineken.UpdateShipment(dto)
    .then(resp => {
      showToastMessage('Informativo', 'Shipment actualizado con éxito')
      return
    })
    .catch(() => {
      showToastMessage('Error', 'Ocurrió un error, favor de intentarlo nuevamente')
      return
    })
  }

  const cancelCreateFacturaTarimas = () => {
    if(FileId > 0){
      FileManagerServices.DeleteFile(FileId)
    }
    setFileId(0)
    setCaja('')
    setTransportista('')
    setShowTarimasModal(false)
  }

  const createFacturaTarimas = () => {
    if(FileId === 0){
      showToastMessage('Error', 'Debe subir la factura XML para guardar.')
      return
    }
    if(Transportista === ''){
      showToastMessage('Error', 'Debe seleccionar un trasportista para guardar.')
      return
    }
    if(Caja === ''){
      showToastMessage('Error', 'Debe capturar el número de caja para guardar.')
      return
    }
    DSHeineken.AppendFacturaTarimas(FileId, Caja, Transportista)
    .then((resp) => {
      setFileId(0)
      setCaja('')
      setTransportista('')
      setShowTarimasModal(false)
      showToastMessage('Informativo', 'Se creó correctamente el registro de la factura')
      loadReport(Inicio, Fin)
      return
    })
    .catch((error: AxiosError) => {
      setFileId(0)
      setCaja('')
      setTransportista('')
      showToastMessage('Error', error.message)
      return
    })
  }

  const showToastMessage = (type: string, message: string) => {
    setHeader(type)
    setMsg(message)
    setShowMsg(true)
  }

  const openFileReplacementModal = (idFile: number, proceso: number) => {
    setFileId(idFile)
    setProcessId(proceso)
    setShowFileReplacementModal(true)
  }

  return (
    <div style={{ padding: '10px' }}>
      <Card>
        <Card.Body>
          <Row className='mb-2'>
            <Col xs={2}>
              <Card.Title>Facturas</Card.Title>
            </Col>
            <Col xs={1}>
            </Col>
            <Col xs={1} style={{ textAlign: 'right' }}>
              Inicio
            </Col>
            <Col xs={1}>
              <Form.Control
                defaultValue={Inicio}
                type='date'
                name='Inicio'
                placeholder='Inicio'
                title='Inicio'
                alt='Inicio'
                data-date-format='YYYY-mm-dd'
                onChange={(e) => setInicio(e.target.value)}
                size='sm'
              />
            </Col>
            <Col xs={1} style={{ textAlign: 'right' }}>
              Hasta
            </Col>
            <Col xs={1}>
              <Form.Control
                defaultValue={Fin}
                type='date'
                name='Inicio'
                placeholder='Inicio'
                title='Inicio'
                alt='Inicio'
                data-date-format='YYYY-mm-dd'
                onChange={(e) => setFin(e.target.value)}
                size='sm'
              />
            </Col>
            
            <Col xs={4}>
              <Form.Control
                type='text'
                size='sm'
                placeholder='Search...'
                onChange={(e) => {
                  filtraReporte(e)
                }}
              />
            </Col>
            <Col xs={1}>
              <Button
                variant='primary'
                onClick={() => {
                  loadReport(Inicio, Fin)
                }}
              >
                <BsSearch />
                &nbsp;Buscar
              </Button>
            </Col>
          </Row>
          <Row className='justify-content-end'>
            <Col xs={5} style={{textAlign:'right'}}>
              <Button variant='success' onClick={() => {selectDownload()}} style={{marginRight:'10px'}}> <FaFileExcel/>&nbsp;Excel</Button>
              <Button variant='success' onClick={() => {selectFile()}} style={{marginRight:'10px'}}><BsUpload/>&nbsp;Shipment</Button>
              <input type="file" ref={hiddenFileInputRef} style={{display:'none'}} accept='.xlsx'
                    onChange={(e:any) => {
                        uploadFile(e.target.files[0]);
                        setFile(e.target.files[0]);
                    }}
              />
              {Perfil === "Administrador" || Perfil === "GEMCO" ? <> 
                <Button variant='success' onClick={() => {openFileUpload('Prefile')}} style={{marginRight:'10px'}}><BsFilePdf/>&nbsp;Prefile</Button>
                <Button variant='success' onClick={() => {openFileUpload('Doda')}} style={{marginRight:'10px'}}><BsFilePdf/>&nbsp;Doda</Button>
                <Button variant='success' onClick={() => {openInvoiceSelection()}} style={{marginRight:'10px'}}><FaFileCirclePlus/>&nbsp;TXT</Button>
                <Button variant='success' onClick={() => {setShowTarimasModal(true)}}><BsFiletypeXml/>&nbsp;XML</Button>
                <input type="file" ref={hiddenInvoiceInputRef} style={{display:'none'}} accept='.txt'
                    onChange={(e:any) => {
                        uploadInvoice(e.target.files[0]);
                        setFile(e.target.files[0]);
                    }}
                />
              </> : ''}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <div style={{ paddingTop: '10px' }}>
        <Col xs={12}>
          <DataTable
            noHeader
            defaultSortFieldId={'id'}
            defaultSortAsc={true}
            pagination
            paginationPerPage={15}
            striped={true}
            dense={true}
            highlightOnHover
            columns={colData}
            data={Data.filter(function (row) {
              return row
            })}
            expandableRows={Perfil === "Administrador" || Perfil === "GEMCO"}
            expandableRowsComponent={({data}) => {
              return (
                <RptFacturasDetail idHeader={data.id} showMsg={
                    (type:string, message: string) => {
                      showToastMessage(type, message)
                    }
                  }
                />
              )
            }}
          />
        </Col>
      </div>
      <Modal
        size='lg'
        show={ShowSelectionModal}
        onHide={()=>{
          setShowSelectionModal(false)
          setBackgroudColorL('')
          setBackgroudColorR('')
        }}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={6}>
              <Card
                style={{border: '3px solid #00b138', cursor:'pointer', backgroundColor:BackgroundColorL, height:'100%'}}
                onMouseEnter={() => {setBackgroudColorL('rgb(0, 177, 56, 0.5)')}}
                onMouseLeave={() => {setBackgroudColorL('')}}
              >
                <Card.Body onClick={()=>downloadExcel()}>
                  <Card.Title>
                    TODAS LAS FACTURAS
                  </Card.Title>
                  <hr/>
                  <Card.Text>
                    Descargue todas facturas que se muestran en pantalla
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={6}>
              <Card
                style={{border: '3px solid #00b138', cursor:'pointer', backgroundColor:BackgroundColorR, height:'100%'}}
                onMouseEnter={() => {setBackgroudColorR('rgb(0, 177, 56, 0.5)')}}
                onMouseLeave={() => {setBackgroudColorR('')}}
              >
                <Card.Body onClick={()=>downloadEmptyShipmentRecords()}>
                  <Card.Title>
                    FACTURAS SIN SHIPMENT
                  </Card.Title>
                  <hr/>
                  <Card.Text>
                    Descargue solamente las facturas que aún no cuentan con un shipment capturado.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
          centered
          show={showSpinner}
      >
          <Modal.Body>
              <div>
                  <p>Procesando el archivo, favor de esperar. Esto puede tardar varios minutos</p>
              </div>
              <Row className="justify-content-center">
              <Spinner animation="border" variant="primary" />
              </Row>
              
          </Modal.Body>
      </Modal>
      <Modal
        centered
        show={showMultiFileUploader}
        onHide={() => {
          setShowMultiFileUploader(false)
        }}
      >
        <Modal.Body>
          <MFileUploader fileType={FileType} hide={(formData: FormData)=> {
            if(FileType === 'Prefile')
              uploadPrefiles(formData)
            else
              uploadDodas(formData)
          }}/>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        show={showTarimasModal}
        onHide={()=>setShowTarimasModal(false)}
        size='lg'
      >
        <Modal.Header>
          <Modal.Title>Cargar XML de Tarimas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              <Row>
                <Col xs={8}>
                  <Form.Label style={{width:'100%'}}>
                    Transportista
                    <Form.Control
                      as='select'
                      className='form-select'
                      onChange={(e) => setTransportista(e.target.value)}
                    >
                      <option key={0} value={''}>- SELECCIONE -</option>
                      {mCatTransportistas.map((t) => {
                        return (
                          <option key={t.id} value={t.sRazonSocial}>{t.sRazonSocial}</option>
                        )
                      })}
                    </Form.Control>
                  </Form.Label>
                </Col>
                <Col xs={4}>
                  <Form.Label>
                    Caja
                    <Form.Control
                      type='text'
                      onChange={(e) => {setCaja(e.target.value)}}
                    />
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <FileManager
                  IDProcess={36}
                  IDUser={UserId} 
                  IdFile={0} 
                  FileName={''} 
                  Leyenda={'Seleccione el XML de la factura de tarimas'} 
                  canDelete={true} 
                  FileType={['xml']}
                  width={700} 
                  height={100} 
                  onAppendFM={(idFile: number) => {setFileId(idFile)}}
                />
              </Row>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' style={{marginRight:'10px'}} onClick={cancelCreateFacturaTarimas}>Cancelar</Button>
          <Button variant='primary' onClick={createFacturaTarimas}>Guardar</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size='lg'
        show={showFileReplacementModal}
        onHide={() => setShowFileReplacementModal(false)}
      >
        <ModalBody>
          <FileManager
            IDProcess={ProcessId}
            IDUser={UserId} 
            IdFile={FileId} 
            FileName={''} 
            Leyenda={''} 
            canDelete={true} 
            FileType={['pdf', 'PDF']}
            width={700} 
            height={100} 
            onAppendFM={(idFile: number) => {setFileId(idFile)}}
          />
        </ModalBody>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
