import http from '../../../Services/common/http-common'
import DTOEmailTransportista from '../DTO/DTOEmailTransportista'
import DTOUsuarioTransporte from '../DTO/DTOUsuarioTransporte'
import ICatTransportista from '../Interfaces/ICatTransportista'
import ICatTransportistaCorreos from '../Interfaces/ICatTransportistaCorreos'
import ISIRTransportista from '../Interfaces/SIR/ISIRTransportista'

class CatTransportistasDataServices {
  GetCatTransportistas() {
    return http.get<ISIRTransportista[]>(
      `/Catalogos/CatTransportistas/GetCatTransportistas`
    )
  }
  GetUsuarioTranportista(id: number) {
    return http.get<DTOUsuarioTransporte>(
      `/Catalogos/CatTransportistas/GetUsuarioTransportista?id=${id}`
    )
  }
  GetEmailsByTransportCompany(id: number) {
    return http.get<ICatTransportistaCorreos[]>(
      `/Catalogos/CatTransportistas/GetEmailsByTransportCompany?id=${id}`
    )
  }
  AppendCorreoTransportista(data: DTOEmailTransportista) {
    return http.post<ICatTransportistaCorreos[]>(
      `/Catalogos/CatTransportistas/AppendCorreoTransportista`,
      data
    )
  }
  DeleteEmail(id: number) {
    return http.delete<Boolean>(
      `/Catalogos/CatTransportistas/DeleteEmail?id=${id}`
    )
  }
}
export default new CatTransportistasDataServices()
