import React, { useEffect, useState } from 'react'
import { Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { DTOFacturaDetail } from '../DTO/DTOFacturaDetail'
import HeinekenServices from '../Services/Heineken.Services'
import { AxiosError } from 'axios'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import { IconContext } from 'react-icons'
import { AiFillSave } from 'react-icons/ai'

interface IProps {
    idHeader: number
    showMsg: (type: string, message: string) => void
}
export const RptFacturasDetail = ({idHeader, showMsg}: IProps) => {
    const [Detail, setDetail] = useState<DTOFacturaDetail[]>([])
    const DetailColDef = [
        {
          name: 'id',
          width: '100px',
          selector: (row: DTOFacturaDetail) => row.id,
          sortable: true,
        },
        {
          name: 'SeisDigitos',
          width: '120px',
          selector: (row: DTOFacturaDetail) => row.seisDigitos,
          sortable: true,
        },
        {
          name: 'VeinteDigitos',
          width: '200px',
          selector: (row: DTOFacturaDetail) => row.veinteDigitos,
          sortable: true,
        },
        {
          name: 'Descripcion',
          width: '250px',
          selector: (row: DTOFacturaDetail) => row.descripcion,
          sortable: true,
        },
        {
          name: 'Medida',
          width: '100px',
          selector: (row: DTOFacturaDetail) => row.medida,
          sortable: true,
        },
        {
          name: 'Num1',
          width: '100px',
          selector: (row: DTOFacturaDetail) => row.num1,
          sortable: true,
        },
        {
          name: 'Num2',
          width: '100px',
          selector: (row: DTOFacturaDetail) => row.num2,
          sortable: true,
        },
        {
          name: 'HNumero4',
          width: '120px',
          selector: (row: DTOFacturaDetail) => row.hNumero4,
          sortable: true,
        },
        {
          name: 'Capacidad',
          width: '130px',
          //selector: (row: DTOFacturaDetail) => row.num3,
          cell: (row: DTOFacturaDetail) => {
            return (
              //Perfil === "Administrador" || Perfil === "GEMCO" || Perfil === 'CLIENTE: HEINEKEN' ?
              <Form.Control
                type='text'
                defaultValue={row.num3}
                onChange={
                  (e) => {
                    row.num3 = +e.target.value
                  }
                }
              />// : row.shipment
            )
          },
          sortable: true,
        },
        {
          name: 'Peso en KG',
          width: '130px',
          //selector: (row: DTOFacturaDetail) => row.num3,
          cell: (row: DTOFacturaDetail) => {
            return (
              //Perfil === "Administrador" || Perfil === "GEMCO" || Perfil === 'CLIENTE: HEINEKEN' ?
              <Form.Control
                type='text'
                defaultValue={row.hNumero6}
                onChange={
                  (e) => {
                    row.hNumero6 = +e.target.value
                  }
                }
              />// : row.shipment
            )
          },
          sortable: true,
        },
        {
          name: '',
          center: true,
          width: '80px',
          //omit: Perfil !== "Administrador" && Perfil !== "GEMCO" && Perfil !== 'CLIENTE: HEINEKEN',
          cell: (row: DTOFacturaDetail) => (
            <OverlayTrigger
              placement='top'
              overlay={ <Tooltip id="button-tooltip-3">Guardar</Tooltip>}
            >
                <IconContext.Provider value={{ color: '#0d6efd', size: '35px' }}>
                  <AiFillSave
                    onClick={() => {
                      updateDetail(row)
                    }}
                  style={{ cursor: 'pointer' }}/>
                </IconContext.Provider>
            </OverlayTrigger>
          ),
        },
        {
          name: 'unidad',
          width: '100px',
          selector: (row: DTOFacturaDetail) => row.unidad,
          sortable: true,
        },
        {
          name: 'HNumero7',
          width: '120px',
          selector: (row: DTOFacturaDetail) => row.hNumero7,
          sortable: true,
        },
        {
          name: 'HNumero8',
          width: '120px',
          selector: (row: DTOFacturaDetail) => row.hNumero8,
          sortable: true,
        },
    ]

    useEffect(() => {
        HeinekenServices.GetFacturaDetail(idHeader)
        .then(resp => setDetail(resp.data))
        .catch((err:AxiosError) => {
          showMsg('Error', err.message)
        })
    }, [])

    const updateDetail = (row: DTOFacturaDetail) => {
      HeinekenServices.UpdateFacturaDetail(row)
      .then(resp => {
        showMsg('Informativo', 'Mercancía actualizada correctamente')
      })
      .catch((error: AxiosError) => {
        showMsg('Error', error.message)
      })
    }

    return (
        <div style={{ paddingTop: '10px' }}>
            <Col xs={12}>
                <DataTable
                noHeader
                defaultSortFieldId={'id'}
                defaultSortAsc={true}
                pagination
                paginationPerPage={15}
                striped={true}
                dense={true}
                highlightOnHover
                columns={DetailColDef}
                data={Detail}
                />
            </Col>
        </div>
      )
}
