import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'

const initialState = {
  value: false,
  perfil: '',
  id: 0
}

export const userStatusSlice = createSlice({
  name: 'userStatus',
  initialState,
  reducers: {
    logued: (state, action: PayloadAction<boolean>) => {
     state.value = action.payload;
    },
    setPerfil: (state, action: PayloadAction<string>) => {
      state.perfil = action.payload;
    },
    setUserId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    }
  },
})

export const { logued, setPerfil, setUserId } = userStatusSlice.actions;
export const selectuserStatus = (state: RootState) => state.userStatus.value
export default userStatusSlice.reducer;
