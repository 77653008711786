import React, { FC, useEffect, useState } from 'react'
import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Row,
} from 'react-bootstrap'
import { RootState } from '../../../store/store'
import DataTable from 'react-data-table-component'
import { IconContext } from 'react-icons'
import {
  BsEraserFill,
  BsFillEraserFill,
  BsFillPencilFill,
} from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { AiFillCloseCircle, AiFillSave } from 'react-icons/ai'
//import CatTransportistasDS from '../Services/CatTransportistas.Services'
import {
  addCatTransportistas,
  populateCatTransportistas,
  updateCatTransportistas,
} from '../../../store/features/CatTransportistasSlice'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import ICatTransportistaCorreos from '../Interfaces/ICatTransportistaCorreos'
import ICatTransportista from '../Interfaces/ICatTransportista'
import CatTransportistasDS from '../../Cliente/Services/CatTransportistas.Services'
import { FcAbout, FcApproval } from 'react-icons/fc'
import ISIRTransportista from '../Interfaces/SIR/ISIRTransportista'
import DTOEmailTransportista from '../DTO/DTOEmailTransportista'

interface IProps {}

export default function CatTransportistas(props: IProps) {
  const dispatch = useDispatch()
  const mCatTransportistas = useSelector(
    (state: RootState) => state.CatTransportistasSlice.CatTransportistas
  )
  const [IDResponsable, setIDResponsable] = useState(0)
  const [Usuario, setUsuario] = useState('')
  const [Correo, setCorreo] = useState('')
  const [ID, setID] = useState(0)
  const [IDCorreo, setIDCorreo] = useState(0)
  const [DataCorreos, setDataCorreos] = useState<ICatTransportistaCorreos[]>([])
  const [show, setShowMsg] = useState(false)
  const [ModalDeleteEmail, setModalDeleteEmail] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')

  const colData = [
    {
      name: 'id',
      width: '100px',
      selector: (row: ICatTransportistaCorreos) => row.id,
      sortable: true,
    },
    {
      name: 'Correo',
      selector: (row: ICatTransportistaCorreos) => row.correo,
    },
    {
      name: '',
      width: '80px',
      cell: (row: ICatTransportistaCorreos) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            setIDCorreo(row.id)
            setCorreo(row.correo)
          }}
        >
          <IconContext.Provider value={{ color: '#339dff', size: '25px' }}>
            <BsFillPencilFill />
          </IconContext.Provider>
        </div>
      ),
    },
    {
      name: '',
      width: '80px',
      cell: (row: ICatTransportistaCorreos) => (
        <div
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => {
            setIDCorreo(row.id)
            setCorreo(row.correo)
            setModalDeleteEmail(true)
          }}
        >
          <IconContext.Provider value={{ color: 'red', size: '25px' }}>
            <AiFillCloseCircle />
          </IconContext.Provider>
        </div>
      ),
    },
  ]

  useEffect(() => {
    loadCatTransportistas()
  }, [])

  useEffect(() => {
    loadEmails(ID)
    loadUsuarioTransportista(ID)
  }, [ID])

  const deleteEmail = () => {
    CatTransportistasDS.DeleteEmail(IDCorreo)
      .then((response) => {
        setIDCorreo(0)
        setCorreo('')
        setModalDeleteEmail(false)
        loadEmails(ID)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadCatTransportistas = () => {
    CatTransportistasDS.GetCatTransportistas()
      .then((response) => {
        console.log(response.data)
        dispatch(populateCatTransportistas(response.data))
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadEmails = (id: number) => {
    CatTransportistasDS.GetEmailsByTransportCompany(id)
      .then((response) => {
        setDataCorreos(response.data)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const loadUsuarioTransportista = (id: number) => {
    CatTransportistasDS.GetUsuarioTranportista(id)
      .then((response) => {
        if (response.data) {
          console.log(JSON.stringify(response.data))
          setUsuario(response.data.usuario)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const saveData = () => {
    if (Usuario.length === 0) {
      setHeader('Error')
      setMsg('El campo usuario no puede estar vacio')
      setShowMsg(true)
    }
    const sCorreo = Correo.replace(/\s+/g, '')
    if (sCorreo.length === 0) {
      setHeader('Error')
      setMsg('El campo correo no puede estar vacio')
      setShowMsg(true)
    }
    const data: DTOEmailTransportista = {
      id: ID,
      Usuario: Usuario,
      IDCorreo: IDCorreo,
      Correo: sCorreo,
    }
    CatTransportistasDS.AppendCorreoTransportista(data)
      .then((response) => {
        loadEmails(ID)
        setHeader('Confirmacion')
        setMsg('Los cambios se han guardado exitosamente.')
        setShowMsg(true)
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  return (
    <div>
      <Row style={{ paddingTop: '20px' }}>
        <Col xs={3}></Col>
        <Col xs={6}>
          <Card>
            <Card.Body>
              <Row style={{ paddingTop: '20px' }}>
                <Col xs={2} style={{ textAlign: 'center' }}>
                  Transportista
                </Col>
                <Col xs={9}>
                  <Form.Select
                    value={ID}
                    onChange={(e) => {
                      setID(parseInt(e.target.value))
                    }}
                  >
                    <option value='0'>Seleccione el transportista</option>
                    {mCatTransportistas
                      ? mCatTransportistas.map((a: ISIRTransportista) => {
                          return (
                            <option value={a.id} key={a.id}>
                              {a.sRazonSocial}
                            </option>
                          )
                        })
                      : null}
                  </Form.Select>
                </Col>
                <Col xs={1} style={{ textAlign: 'right' }}>
                  <div
                    title='De un click aqui para guardar nuevas credenciales'
                    onClick={() => {
                      saveData()
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <IconContext.Provider
                      value={{ color: '#002f87', size: '30px' }}
                    >
                      <AiFillSave />
                    </IconContext.Provider>
                  </div>
                </Col>
              </Row>
              <Row style={{ paddingTop: '10px' }}>
                <Col xs={5}>
                  <FloatingLabel controlId='floatingInput' label='Usuario'>
                    <Form.Control
                      type='text'
                      value={Usuario}
                      size='sm'
                      onChange={(e) => {
                        setUsuario(e.target.value)
                      }}
                      disabled={ID === 0}
                    />
                  </FloatingLabel>
                </Col>
                <Col xs={6}>
                  <FloatingLabel controlId='floatingInput' label='Correo'>
                    <Form.Control
                      type='email'
                      value={Correo}
                      size='sm'
                      onChange={(e) => {
                        setCorreo(e.target.value)
                      }}
                      disabled={ID === 0}
                    />
                  </FloatingLabel>
                </Col>
                <Col style={{ paddingTop: '10px' }}>
                  <Button
                    variant='warning'
                    size='sm'
                    onClick={() => {
                      setCorreo('')
                    }}
                  >
                    <BsEraserFill />
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <DataTable
                    noHeader
                    defaultSortFieldId={'id'}
                    defaultSortAsc={true}
                    pagination
                    highlightOnHover
                    columns={colData}
                    data={DataCorreos.filter(function (row) {
                      return row
                    })}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={3}></Col>
      </Row>

      <Modal
        show={ModalDeleteEmail}
        onHide={() => setModalDeleteEmail(false)}
        size='lg'
      >
        <Modal.Body>
          <Card className='labelSize13px'>
            <Card.Body>
              <Card>
                <Row>
                  <Col xs={12}>
                    <Alert variant='warning' style={{ textAlign: 'center' }}>
                      <h6>
                        Esta seguro de eliminar este correo ({Correo})?
                        <br />
                      </h6>
                    </Alert>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  <Col></Col>
                  <Col xs={1}>
                    <Button
                      variant='secondary'
                      style={{ textAlign: 'right' }}
                      onClick={() => {
                        setModalDeleteEmail(false)
                      }}
                    >
                      Cerrar
                    </Button>
                  </Col>
                  <Col xs={8}></Col>
                  <Col xs={2}>
                    <Button
                      variant='danger'
                      onClick={() => {
                        deleteEmail()
                      }}
                    >
                      Eliminar
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>

      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
