import http from "../common/http-common";
import ILogin from "../../Interfaces/Ilogin"
import ItemMenuData  from "../../Interfaces/Catalogos/IItemMenu";
import Token from '../../Interfaces/token'
import IRespuesta from "../../Interfaces/IRespuesta";
import { DTOPINUsuario } from "../../DTO/DTOPINUsuario";
import DTOUsuario from "../../DTO/DTOUsuario";
import { DTOResetPassword } from "../../DTO/DTOResetPassword";

class DashboardDataService {

  getFacturas(){
    return http.get(`Dashboard/GetFacturas`);
  }
  getProgramaciones(){
    return http.get(`Dashboard/GetProgramaciones`);
  }
  getCruces(fecha: string){
    return http.get(`Dashboard/GetCruces?Fecha=${fecha}`);
  }
}
export default new DashboardDataService();