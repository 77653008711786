import React, { ElementType, useState } from 'react'
import { Col, Card, Button, Row, Form } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { FaFileInvoice } from 'react-icons/fa6'
import { Route, useNavigate } from 'react-router-dom'

interface IProps {
    cardHeight: string
    cardWidth: string
    cardHeader: string
    iconColor: string
    iconSize: string
    Icon: ElementType
    data: string
    route: string
    date?: string
    onChange?: (date: string) => void
    showDateSelector?: boolean
}

export const DashboardItem = ({cardHeight, cardWidth, cardHeader, iconColor, iconSize, data, route, Icon, date='', onChange = () =>{}, showDateSelector=false}:IProps) => {
    const navigate = useNavigate();
  return (
    <Col>
        <Card style={{height: cardHeight, width: cardWidth}}>
            <Card.Header style={{textAlign:'left'}}><h4>{cardHeader}</h4></Card.Header>
            <Card.Body>
                <IconContext.Provider value={{ color: iconColor, size: iconSize}}>
                    <div style={{display:'flex', height:'100%', alignItems:'center', justifyContent:'center'}}>
                        <span style={{fontSize:'100px'}}>{data}</span>{Icon && <Icon/>}
                    </div>
                </IconContext.Provider>
            </Card.Body>
            <Card.Footer>
                <Row className='justify-content-between align-items-center'>
                    <Col xs={6}>
                        { showDateSelector ? 
                        <Form.Control
                            defaultValue={date}
                            type='date'
                            name='Inicio'
                            placeholder='Inicio'
                            title='Inicio'
                            alt='Inicio'
                            data-date-format='YYYY-mm-dd'
                            onChange={(e) => onChange(e.target.value)}
                        /> : ''}
                    </Col>
                    <Col xs={6}>
                        <Button variant='link' size='lg' onClick={() => navigate(route)}> ver mas...</Button>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    </Col>
  )
}
