import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ISIRTransportista from '../../Components/Cliente/Interfaces/SIR/ISIRTransportista'
const CatTransportistas: ISIRTransportista[] = [
  {
    id: 0,
    sRazonSocial: '',
  },
]
const initialState = { CatTransportistas }

export const CatTransportistasSlice = createSlice({
  name: 'CatTransportistas',
  initialState: initialState,
  reducers: {
    populateCatTransportistas: (
      state,
      action: PayloadAction<ISIRTransportista[]>
    ) => {
      state.CatTransportistas = []
      state.CatTransportistas.push(...action.payload)
    },
    addCatTransportistas: (state, action: PayloadAction<ISIRTransportista>) => {
      var Existe = state.CatTransportistas.find(function (item) {
        return item.id === action.payload.id
      })
      if (!Existe) state.CatTransportistas.push(action.payload)
    },
    updateCatTransportistas: (
      state,
      action: PayloadAction<ISIRTransportista>
    ) => {
      const i = state.CatTransportistas.findIndex(
        (_element) => _element.id === action.payload.id
      )
      if (i > -1) state.CatTransportistas[i] = action.payload
      else state.CatTransportistas.push(action.payload)
    },
  },
})

export const {
  addCatTransportistas,
  populateCatTransportistas,
  updateCatTransportistas,
} = CatTransportistasSlice.actions
export default CatTransportistasSlice.reducer
