import React, { useRef, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { BsFileEarmarkPdf, BsFileEarmarkPdfFill, BsUpload } from 'react-icons/bs';
import { FaCircleXmark, FaRegFileExcel, FaRegFilePdf } from 'react-icons/fa6';
import FileManagerServices from '../../../Services/Utils/FileManager.Services';
import { MsgInformativo } from '../Toast/msgInformativo';
import { AxiosError } from 'axios';

interface IProps {
    hide: (formData: FormData) => void
    fileType: string
}

export const MFileUploader = ({hide, fileType}:IProps) => {

    const hiddenFileInputRef = useRef<HTMLInputElement>(null);
    const [Files, setFiles] = useState<File[]>([]);
    //
    const [show, setShowMsg] = useState(false)
    const [header, setHeader] = useState('')
    const [msg, setMsg] = useState('')
    const [msgColor, setMsgColor] = useState('primary')

    const removeFile = (index:number) =>{
        let files = Files.filter((file, idx) => {
            return idx !== index
        })
        setFiles(files)
    }

    const upload = () => {
        const formData = new FormData()
        Files.forEach(file => {
            formData.append("files", file)
        })
        hide(formData);
    }
    
    return (
        <>
            <Row style={{justifyContent:'center', alignItems:'center', padding:'0px 15px 0px 15px'}}>
                {   Files.length === 0 ? 
                    <form 
                        style={{
                            display: 'flex', flexDirection:'column', justifyContent:'center',
                            alignItems:'center', border: '2px dashed #1475cf', borderRadius:'5px',
                            height: '300px', width:'500px', cursor: 'pointer',
                        }}
                        onClick={() => {hiddenFileInputRef.current?.click()}}
                    >
                        <input type="file" ref={hiddenFileInputRef} style={{display:'none'}}
                            accept='.pdf'
                            multiple
                            onChange={(e:any) => {
                                console.log(e.target.files)
                                setFiles([...e.target.files]);
                            }}
                        />
                        
                                <>
                                    <BsUpload style={{fontSize:'60px', color:'#1475cf'}}/>
                                    <span style={{display:'block', paddingTop:'10px'}}>Click aquí para seleccionar los {fileType}...</span>
                                </>
                        
                    </form>
                : 
                    <div style={{
                        display: 'flex', flexDirection:'column', justifyContent:'center',
                        alignItems:'center', border: '2px dashed #1475cf', borderRadius:'5px',
                        height: '300px', width:'500px', overflowY:'scroll'
                    }}
                    >
                        {
                            Files.map((file, index) => {
                            return (
                                    <div style={{width:'100%'}}>
                                        <span style={{display: 'flex', justifyContent:'space-between', alignItems:'center', paddingTop:'10px'}}>
                                            <p><BsFileEarmarkPdfFill style={{color:'#bd2020'}}/> {file.name}</p> <FaCircleXmark style={{color:'red', cursor:'pointer'}} onClick={() => removeFile(index)}/>
                                        </span>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </Row>
            <Row className='pt-2 justify-content-end'>
                <Col xs={4} style={{textAlign:'end'}}>
                    <Button variant='primary' onClick={upload}>Subir Archivos</Button>
                </Col>
            </Row>
            <MsgInformativo
                show={show}
                msg={msg}
                header={header}
                time={2000}
                msgColor={msgColor}
                closeToast={() => {
                    setShowMsg(false)
                }}
            />
        </>
    )
}
