import { useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Form, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap'
import { BsCircleFill, BsFileEarmarkPdfFill, BsSearch, BsUpload } from 'react-icons/bs'
import '../../../css/masterDetail.css'
import '../../../css/generalStyles.css'
import DSHeineken from '../Services/Heineken.Services'
import DataTable from 'react-data-table-component'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import xlsx from 'json-as-xlsx'
import DTORptCruces from '../DTO/DTORptCruces'
import { IconContext } from 'react-icons'
import FileManagerServices from '../../../Services/Utils/FileManager.Services'
import { FaCircleCheck, FaCircleXmark, FaEye, FaFileExcel, FaPencil } from 'react-icons/fa6'
import { AxiosError } from 'axios'
import { AiFillSave } from 'react-icons/ai'

export interface IFtpFacturasProps {}

export default function RptCruces(props: IFtpFacturasProps) {
  const [Perfil, setPerfil] = useState(() => {
    const stickyValue = window.localStorage.getItem('Perfil')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Data, setData] = useState<DTORptCruces[]>([])
  const [DataOriginal, setDataOriginal] = useState<DTORptCruces[]>([])
  const [Inicio, setInicio] = useState(currentDate(-30))
  const [Fin, setFin] = useState(currentDate(0))
  const [filtro, setFiltro] = useState('')
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);
  const [showSpinner, setShowSpinner] = useState(false)
  const [ShowSelectionModal, setShowSelectionModal] = useState(false)
  const [File, setFile] = useState<File | null>();
  const [BackgroundColorL, setBackgroudColorL] = useState('')
  const [BackgroundColorR, setBackgroudColorR] = useState('')
  const [ShowShipments, setShowShipments] = useState(false)
  const [ShipmentsViewHeader, setShipmentsViewHeader] = useState('')
  const [Shipments, setShipments] = useState('')

  const colData = [
    {
      name: 'id',
      width: '100px',
      selector: (row: DTORptCruces) => row.id,
      sortable: true,
    },
    {
      name: 'Prefile',
      center: true,
      width: '80px',
      cell: (row: DTORptCruces) => (
        <OverlayTrigger
          placement='top'
          overlay={ row.fechaPrefile ? <Tooltip id="button-tooltip-2">{row.fechaPrefile}</Tooltip> : <br style={{display:'none'}}/>}
        >
        <div style={
          row.fechaPrefile === '' ? {
            pointerEvents:'none',
            opacity: 0.5
          } : {
            
          }
        }>
          <IconContext.Provider value={{ color: 'red', size: '17px' }}>
            <BsFileEarmarkPdfFill 
              onClick={() => {
                GetPrefile(row.factura, row.factura2)
              }}
              style={{ cursor: 'pointer' }}
            />
          </IconContext.Provider>
        </div>
        </OverlayTrigger>
      ),
    },
    {
      name: 'Doda',
      center: true,
      width: '80px',
      cell: (row: DTORptCruces) => (
        <OverlayTrigger
          placement='top'
          overlay={ row.fechaDoda ? <Tooltip id="button-tooltip-2">{row.fechaDoda}</Tooltip> : <br style={{display:'none'}}/>}
        >
          <div 
            style={
              row.fechaDoda === '' ? {
                pointerEvents:'none',
                opacity: 0.5
              } : {
                
              }
            }
          >
            <IconContext.Provider value={{ color: 'red', size: '17px' }}>
              <BsFileEarmarkPdfFill 
              onClick={() => {
                GetDoda(row.id)
              }}
              style={{ cursor: 'pointer' }}/>
            </IconContext.Provider>
          </div>
        </OverlayTrigger>
      ),
    },
    {
      name: 'BL',
      center: true,
      width: '60px',
      cell: (row: DTORptCruces) => (
        <div
          onClick={() => {
            ShowBL2PDF(row.id)
          }}
          style={{ cursor: 'pointer' }}
        >
          <IconContext.Provider value={{ color: 'red', size: '17px' }}>
            <BsFileEarmarkPdfFill />
          </IconContext.Provider>
        </div>
      ),
    },
    {
      name: 'Folio Carta Porte',
      width: '300px',
      //selector: (row: DTORptCruces) => row.folioCartaPorte,
      cell: (row: DTORptCruces) => (
        <OverlayTrigger
          placement='top'
          overlay={ row.fechaHoraFCP !== '' ? <Tooltip id="button-tooltip-2">{row.fechaHoraFCP}</Tooltip> : <br style={{display:'none'}}/>}
        >
          <span style={{ cursor: 'pointer' }}>{row.folioCartaPorte}</span>
        </OverlayTrigger>
      ),
      sortable: true,
    },
    {
      name: 'SCAC',
      width: '100px',
      selector: (row: DTORptCruces) => row.scac,
      sortable: true,
    },
    {
      name: 'CAAT',
      width: '100px',
      selector: (row: DTORptCruces) => row.caat,
      sortable: true,
    },
    {
      name: 'Transportista Americano',
      width: '200px',
      cell: (row: DTORptCruces) => (
        <div
          style={{
            alignItems:'center',
            textAlign: 'center',
            cursor: 'pointer',
            width:'100%'
          }}
        >
          <IconContext.Provider value={{ color: row.transporteAmericano ? 'green' : 'red', size: '18px' }}>
            {row.transporteAmericano ? <FaCircleCheck/> : <FaCircleXmark/>}
          </IconContext.Provider>
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Trafico',
      width: '100px',
      selector: (row: DTORptCruces) => row.trafico,
      sortable: true,
    },
    {
      name: 'Fecha Estimada Cruce',
      width: '200px',
      selector: (row: DTORptCruces) => row.fechaEstimadaCruce,
      sortable: true,
    },
    {
      name: 'Fecha Cita',
      width: '150px',
      selector: (row: DTORptCruces) => row.fechaCita,
      sortable: true,
    },
    {
      name: 'Fecha de Cruce',
      width: '150px',
      selector: (row: DTORptCruces) => {
        return row.fechaCruce.substring(0,10) + " " + row.fechaCruce.substring(11,20)
      },
      sortable: true,
    },
    {
      name: 'Semaforo',
      width: '100px',
      cell: (row: DTORptCruces) => {
        return row.semaforo && row.semaforo.toUpperCase() === 'V' ? 
          <div style={{width: '100%', textAlign:"center"}}><BsCircleFill style={{color:'green'}}/></div> :
          <div style={{width: '100%', textAlign:"center"}}><BsCircleFill style={{color:'red'}}/></div>
      },
      sortable: true,
    },
    {
      name: 'Proveedor',
      width: '200px',
      selector: (row: DTORptCruces) => row.proveedor,
      sortable: true,
    },
    {
      name: 'Transportista',
      width: '200px',
      selector: (row: DTORptCruces) => row.transportista,
      sortable: true,
    },
    {
      name: 'Factura',
      selector: (row: DTORptCruces) => row.factura,
      sortable: true,
    },
    {
      name: 'Factura2',
      selector: (row: DTORptCruces) => row.factura2,
      sortable: true,
    },
    {
      name: 'Pedido',
      selector: (row: DTORptCruces) => row.pedido,
      sortable: true,
    },
    {
      name: 'Pedido SAP',
      selector: (row: DTORptCruces) => row.pedidoSAP,
      sortable: true,
    },
    {
      name: 'Delivery',
      selector: (row: DTORptCruces) => row.delivery,
      sortable: true,
    },
    {
      name: '',
      width: '50px',
      omit: Perfil !== "Administrador" && Perfil !== "GEMCO",
      //selector: (row: DTORptCruces) => row.shipment,
      cell: (row: DTORptCruces) => {
        return (
          <IconContext.Provider value={{ color: 'blue', size: '18px' }}>
            <FaEye onClick={() => {openShipmentsView(row.pedimento)}} style={{cursor:'pointer'}}/>
          </IconContext.Provider>
        )
      },
      sortable: true,
    },
    {
      name: 'Shipment',
      width: '150px',
      // selector: (row: DTORptCruces) => row.shipment,
      cell: (row: DTORptCruces) => {
        return (
          Perfil === "Administrador" || Perfil === "GEMCO" || Perfil === 'CLIENTE: HEINEKEN' ?
          <Form.Control
            type='text'
            defaultValue={row.shipment}
            onChange={
              (e) => {
                row.shipment = e.target.value
              }
            }
          /> : row.shipment
        )
      },
      sortable: true,
    },
    {
      name: '',
      center: true,
      width: '80px',
      omit: Perfil !== "Administrador" && Perfil !== "GEMCO" && Perfil !== 'CLIENTE: HEINEKEN',
      cell: (row: DTORptCruces) => (
        <OverlayTrigger
          placement='top'
          overlay={ <Tooltip id="button-tooltip-3">Guardar</Tooltip>}
        >
            <IconContext.Provider value={{ color: '#0d6efd', size: '35px' }}>
              <AiFillSave
                onClick={() => {
                  updateShipment(row)
                }}
              style={{ cursor: 'pointer' }}/>
            </IconContext.Provider>
        </OverlayTrigger>
      ),
    },
    {
      name: 'Destino',
      selector: (row: DTORptCruces) => row.destino,
      sortable: true,
    },
    {
      name: 'Fecha',
      selector: (row: DTORptCruces) => row.fechaFactura,
      sortable: true,
    },
    {
      name: 'Aduana',
      selector: (row: DTORptCruces) => row.aduana,
      sortable: true,
    },
    {
      name: 'Patente',
      selector: (row: DTORptCruces) => row.patente,
      sortable: true,
    },
    {
      name: 'Pedimento',
      selector: (row: DTORptCruces) => row.pedimento,
      sortable: true,
    },
    {
      name: 'Caja',
      width: '180px',
      selector: (row: DTORptCruces) => row.caja,
      sortable: true,
    },
    {
      name: 'Bultos',
      selector: (row: DTORptCruces) => row.bultos,
      sortable: true,
    },
  ]

  const openShipmentsView = (pedimento: string) => {
    let facturas = Data.filter(x => x.pedimento === pedimento)
    let header = `Pedimento: ${facturas[0].aduana}-${facturas[0].patente}-${facturas[0].pedimento} | ${facturas.length} Remesas | `
    let shipmentsFaltantes = 0
    var shipments = ''
    facturas.forEach((f, index) => {
      if(f.shipment !== ''){
        shipments += `${f.shipment}, `
      }else{
        shipments += '-, '
        shipmentsFaltantes ++
      }
      if(index === facturas.length-1){
        shipments = shipments.substring(0, shipments.length-2);
      }
    })
    setShipmentsViewHeader(header += `${shipmentsFaltantes} Shipments Faltantes`)
    setShipments(shipments)
    setShowShipments(true)
  }

  function currentDate(days: number): string {
    var today = new Date()
    today.setDate(today.getDate() + days)
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0')
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  const loadReport = (Inicio: string, Fin: string) => {
    DSHeineken.GetCruces(Inicio, Fin)
      .then((response) => {
        console.log(response.data)
        setData(response.data)
        setDataOriginal(response.data)
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  useEffect(() => {
    loadReport(Inicio, Fin)
  }, [])

  const filtraReporte = (e: any) => {
    setFiltro(e.target.value)
    if (e.target.value.length > 0) {
      setData(
        Data.filter(function (row) {
          return (
            row.fechaFactura
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.factura
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.proveedor
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.destino
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.pedidoSAP
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.caja
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.transportista
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.aduana
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.patente
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.pedimento
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.trafico
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) 
          )
        })
      )
    } else {
      setData(DataOriginal)
    }
  }

  function exportExcel(jsonData: DTORptCruces[], fileName: string): void {
    let Heading = [
      [
        'Proveedor',
        'Factura',
        'Pedido',
        'Pedido SAP',
        'Delivery',
        'Shipment',
        'Destino',
        'Fecha de factura',
        'Aduana',
        'Patente',
        'Pedimento',
        'Caja',
        'Bultos',
      ],
    ]
    const dataOnly = jsonData.map(
      ({
        proveedor,
        factura,
        pedido,
        pedidoSAP,
        delivery,
        shipment,
        destino,
        fechaFactura,
        aduana,
        patente,
        pedimento,
        caja,
        bultos,
      }) => {
        return {
          proveedor,
          factura,
          pedido,
          pedidoSAP,
          delivery: delivery.substring(2),
          shipment,
          destino,
          fechaFactura: { fechaFactura },
          aduana,
          patente,
          pedimento,
          caja,
          bultos,
        }
      }
    )
    let data = [
      {
        sheet: 'Facturas',
        columns: [
          { label: 'Proveedor', value: 'proveedor' },
          { label: 'Factura', value: 'factura' },
          { label: 'Pedido', value: 'pedido' },
          { label: 'Pedido SAP', value: 'pedidoSAP' },
          { label: 'Delivery', value: 'delivery' },
          { label: 'Shipment', value: 'shipment' },
          { label: 'Destino', value: 'destino' },
          {
            label: 'Fecha',
            value: (row: any) =>
              row.fechaFactura.fechaFactura
                ? row.fechaFactura.fechaFactura.substring(0, 10)
                : '',
          },
          { label: 'Aduana', value: 'aduana' },
          { label: 'Patente', value: 'patente' },
          { label: 'Pedimento', value: 'fechaEnvio' },
          { label: 'Caja', value: 'caja' },
          { label: 'Bultos', value: 'bultos' },
        ],
        content: dataOnly,
      },
    ]
    let settings = {
      fileName,
      extraLength: 3,
      writeOptions: {},
      RTL: false,
    }
    xlsx(data, settings)
  }

  const selectFile = () => {
    hiddenFileInputRef.current?.click()
  }

  const uploadFile = (file: File) => {
    setShowSpinner(true)
    const formData = new FormData()
    formData.append('name', file.name)
    formData.append('file', file)
    DSHeineken.AppendShipmentsXLSX(formData)
    .then((res) => {
        hiddenFileInputRef.current!.value = ''
        setShowSpinner(false)
        setShowMsg(true)
        setMsg("Shipments actualizados correctamente")
        setHeader('Informativo')
        loadReport(Inicio, Fin)
    })
    .catch((err: AxiosError) => {
        setShowSpinner(false)
        hiddenFileInputRef.current!.value = ''
        setShowMsg(true)
        setMsg(err.request.responseText)
        setHeader('Error')
        return
    })
  }

  const selectDownload = () => {
    if(Data.length === 0){
      setShowMsg(true)
      setMsg('Sin facturas para descargar')
      setHeader('Informativo')
      return
    }
    setShowSelectionModal(true)
  }

  const downloadExcel = () => {
    exportExcel(Data, 'Reporte de Cruces')
    setShowSelectionModal(false)
  }

  const downloadEmptyShipmentRecords = () => {
    let filteredData = Data.filter(x => x.shipment === '')
    exportExcel(filteredData, 'Reporte de Facturas')
    setShowSelectionModal(false)
  }

  const ShowBL2PDF = (id: number) => {
    const newWindow = window.open(
      process.env.REACT_APP_SERVICES +
        'heineken/services/BOL2PDF' +
        '?id=' +
        id,
      '_blank',
      'noopener,noreferrer'
    )
    if (newWindow) newWindow.opener = null
  }

  const GetPrefile = (Factura: string, Factura2: string) => {
    FileManagerServices.getPrefile(Factura, Factura2.substring(2))
      .then((response: any) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Prefile no existe')
        setShowMsg(true)
        return
      })
  }
  
  const GetDoda = (id: number) => {
    FileManagerServices.getFileContent(id, 34)
    .then((response: any) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        window.open(url)
      }
    })
    .catch((e: Error) => {
      setHeader('Error')
      setMsg('Doda no existe')
      setShowMsg(true)
      return
    })
  }

  const updateShipment = (dto: DTORptCruces) => {
    if(dto.shipment === ''){
      setHeader('Error')
      setMsg('El shipment no puede ir en blanco')
      setShowMsg(true)
      return
    }
    DSHeineken.UpdateShipment(dto)
    .then(resp => {
      setHeader('Informativo')
      setMsg('Shipment actualizado con éxito')
      setShowMsg(true)
      return
    })
    .catch(() => {
      setHeader('Error')
      setMsg('Ocurrió un error, favor de intentarlo nuevamente')
      setShowMsg(true)
      return
    })
  }

  return (
    <div>
      <Row style={{ paddingTop: '10px' }}>
        <Col xs={12}></Col>
      </Row>
      <Card>
        <Card.Body>
          <Row className='mb-2'>
            <Col xs={2}>
              <Card.Title>Facturas</Card.Title>
            </Col>
            <Col xs={1}>
              {/* <Button variant='success' size='sm' onClick={() => { downloadExcel() }} > Excel </Button> */}
            </Col>
            <Col xs={1} style={{ textAlign: 'right' }}>
              Inicio
            </Col>
            <Col xs={1}>
              <Form.Control
                defaultValue={Inicio}
                type='date'
                name='Inicio'
                placeholder='Inicio'
                title='Inicio'
                alt='Inicio'
                data-date-format='YYYY-mm-dd'
                onChange={(e) => setInicio(e.target.value)}
                size='sm'
              />
            </Col>
            <Col xs={1} style={{ textAlign: 'right' }}>
              Hasta
            </Col>
            <Col xs={1}>
              <Form.Control
                defaultValue={Fin}
                type='date'
                name='Inicio'
                placeholder='Inicio'
                title='Inicio'
                alt='Inicio'
                data-date-format='YYYY-mm-dd'
                onChange={(e) => setFin(e.target.value)}
                size='sm'
              />
            </Col>
            <Col xs={1}>
              <Button
                variant='primary'
                size='sm'
                onClick={() => {
                  loadReport(Inicio, Fin)
                }}
              >
                <BsSearch />
                Buscar
              </Button>
            </Col>
            <Col xs={4}>
              <Form.Control
                type='text'
                size='sm'
                placeholder='Search...'
                onChange={(e) => {
                  filtraReporte(e)
                }}
              />
            </Col>
          </Row>
          <Row className='justify-content-end'>
            <Col xs={4} style={{textAlign:'right'}}>
              <Button variant='success' onClick={() => {selectDownload()}} style={{marginRight:'10px'}}> <FaFileExcel/>&nbsp;Excel</Button>
              <Button variant='success' onClick={() => {selectFile()}} style={{marginRight:'10px'}}><BsUpload/>&nbsp;Shipment</Button>
              <input type="file" ref={hiddenFileInputRef} style={{display:'none'}} accept='.xlsx'
                    onChange={(e:any) => {
                        uploadFile(e.target.files[0]);
                        setFile(e.target.files[0]);
                    }}
                />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row style={{ paddingTop: '10px' }}>
        <Col xs={12}>
          <DataTable
            noHeader
            defaultSortFieldId={'id'}
            defaultSortAsc={true}
            pagination
            paginationPerPage={15}
            striped={true}
            dense={true}
            highlightOnHover
            columns={colData}
            data={Data.filter(function (row) {
              return row
            })}
          />
        </Col>
      </Row>
      <Modal
        size='lg'
        show={ShowSelectionModal}
        onHide={()=>{
          setShowSelectionModal(false)
          setBackgroudColorL('')
          setBackgroudColorR('')
        }}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={6}>
              <Card
                style={{border: '3px solid #00b138', cursor:'pointer', backgroundColor:BackgroundColorL, height:'100%'}}
                onMouseEnter={() => {setBackgroudColorL('rgb(0, 177, 56, 0.5)')}}
                onMouseLeave={() => {setBackgroudColorL('')}}
              >
                <Card.Body onClick={()=>downloadExcel()}>
                  <Card.Title>
                    TODAS LAS FACTURAS
                  </Card.Title>
                  <hr/>
                  <Card.Text>
                    Descargue todas facturas que se muestran en pantalla
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={6}>
              <Card
                style={{border: '3px solid #00b138', cursor:'pointer', backgroundColor:BackgroundColorR, height:'100%'}}
                onMouseEnter={() => {setBackgroudColorR('rgb(0, 177, 56, 0.5)')}}
                onMouseLeave={() => {setBackgroudColorR('')}}
              >
                <Card.Body onClick={()=>downloadEmptyShipmentRecords()}>
                  <Card.Title>
                    FACTURAS SIN SHIPMENT
                  </Card.Title>
                  <hr/>
                  <Card.Text>
                    Descargue solamente las facturas que aún no cuentan con un shipment capturado.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
          centered
          show={showSpinner}
      >
          <Modal.Body>
              <div>
                  <p>Procesando el archivo, favor de esperar. Esto puede tardar varios minutos</p>
              </div>
              <Row className="justify-content-center">
              <Spinner animation="border" variant="primary" />
              </Row>
              
          </Modal.Body>
      </Modal>
      <Modal
          centered
          show={ShowShipments}
          size='lg'
          onHide={() => setShowShipments(false)}
      >
        <Modal.Header closeButton><h5 style={{marginBottom:0}}>{ShipmentsViewHeader}</h5></Modal.Header>
        <Modal.Body>
            <Card>
              <Card.Body>
                <span>{Shipments}</span>
              </Card.Body>
            </Card>
        </Modal.Body>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
