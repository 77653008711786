import { DTONotificacionesTransportista } from '../Components/Cliente/DTO/DTONotificaciones';
import http from './common/http-common'

class NotificacionesTransportistaService{
    GetNotificaciones(id: number){
        return http.get<DTONotificacionesTransportista[]>(`NotificacionesTransportista?id=${id}`)
    }
    MarkAsRead(id: number){
        return http.post<DTONotificacionesTransportista[]>(`NotificacionesTransportista/MarkAsRead?id=${id}`)
    }
}

export default new NotificacionesTransportistaService();