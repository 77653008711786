import { FC, useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Form, Row, Table } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { BsChevronDown, BsChevronRight, BsCircleFill, BsSearch } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import '../../../css/masterDetail.css'
import '../../../css/generalStyles.css'
import DSHeineken from '../Services/Heineken.Services'
import IFacturasTxt from '../Interfaces/IFacturasHeader'

interface IProps {}

export default function RptFacturasTxt(props: IProps) {
  const dispatch = useDispatch()
  const Info = JSON.parse(localStorage.getItem('tokenInfo') || '[]')
  const [showTripDialog, setShowTripDialog] = useState(false)
  const [ShowDeleteDialog, setShowDeleteDialog] = useState(false)
  const [ShowDocumentsDialog, setShowDocumentsDialog] = useState(false)
  const [Data, setData] = useState<IFacturasTxt[]>([])
  const [DataOriginal, setDataOriginal] = useState<IFacturasTxt[]>([])
  const [Inicio, setInicio] = useState(currentDate(0))
  const [Fin, setFin] = useState(currentDate(0))
  const [filtro, setFiltro] = useState('')
  const [IDViaje, setIDViaje] = useState(0)
  const [IDCliente, setIDCliente] = useState(0)
  const [SCliente, setSCliente] = useState('')
  const [Search, setSearch] = useState('')
  const [IDUsuario, setIDUsuario] = useState(0)
  const [toggle, setToggle] = useState(false)

  function currentDate(days: number): string {
    var today = new Date()
    today.setDate(today.getDate() + days)
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0')
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  const loadReport = (Inicio: string, Fin: string) => {
    DSHeineken.GetFacturasTxt(Inicio, Fin)
      .then((response) => {
        console.log(response.data)
        setData(response.data)
        setDataOriginal(response.data)
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  useEffect(() => {
    loadReport(currentDate(0), currentDate(0))
  }, [])

  const changeToggle = (row: IFacturasTxt) => {
    let x = Data.filter((a) => {
      if (a.id === row.id) {
        return a
      }
    })
    if (x) {
      const obj = { ...x[0] }
      obj.max = !obj.max
      const DataCopy = Data
      const i = DataCopy.findIndex((_element) => _element.id === row.id)
      if (i > -1) DataCopy[i] = obj
      setData([...DataCopy])
    }
  }

  const filtraReporte = (e: any) => {
    setFiltro(e.target.value)
    if (e.target.value.length > 0) {
      setData(
        Data.filter(function (row) {
          return (
            row.delivery
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.fecha
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.factura
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.poCliente
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.heineken
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.destino
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.moneda
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.numero4.toString().includes(e.target.value) ||
            row.numero5.toString().includes(e.target.value) ||
            row.numero6.toString().includes(e.target.value) ||
            row.unidad
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.numero7.toString().includes(e.target.value) ||
            row.numero8.toString().includes(e.target.value) ||
            row.incoterm
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.incoterm2
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.pedidoSAP
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.transportista
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.caja
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.numero12.toString().includes(e.target.value) ||
            row.detail.filter(
              (det) =>
                det.seisDigitos.toString().includes(e.target.value) ||
                det.veinteDigitos.toString().includes(e.target.value) ||
                det.descripcion
                  .toLocaleLowerCase()
                  .includes(e.target.value.toLocaleLowerCase()) ||
                det.medida.toString().includes(e.target.value) ||
                det.num1.toString().includes(e.target.value) ||
                det.num2.toString().includes(e.target.value) ||
                det.num3.toString().includes(e.target.value) ||
                det.unidad
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase()) ||
                det.hNumero4.toString().includes(e.target.value) ||
                det.hNumero6.toString().includes(e.target.value) ||
                det.hNumero7.toString().includes(e.target.value) ||
                det.hNumero8.toString().includes(e.target.value)
            ).length > 0
          )
        })
      )
    } else {
      setData(DataOriginal)
    }
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={3}>
              <Card.Title>Facturas TXT</Card.Title>
            </Col>
            <Col xs={1} style={{ textAlign: 'right' }}>
              Inicio
            </Col>
            <Col xs={1}>
              <Form.Control
                defaultValue={Inicio}
                type='date'
                name='Inicio'
                placeholder='Inicio'
                title='Inicio'
                alt='Inicio'
                data-date-format='YYYY-mm-dd'
                onChange={(e) => setInicio(e.target.value)}
                size='sm'
              />
            </Col>
            <Col xs={1} style={{ textAlign: 'right' }}>
              Hasta
            </Col>
            <Col xs={1}>
              <Form.Control
                defaultValue={Fin}
                type='date'
                name='Inicio'
                placeholder='Inicio'
                title='Inicio'
                alt='Inicio'
                data-date-format='YYYY-mm-dd'
                onChange={(e) => setFin(e.target.value)}
                size='sm'
              />
            </Col>
            <Col xs={1}>
              <Button
                variant='primary'
                size='sm'
                onClick={() => {
                  loadReport(Inicio, Fin)
                }}
              >
                <BsSearch />
                Buscar
              </Button>
            </Col>
            <Col xs={4}>
              <Form.Control
                type='text'
                size='sm'
                placeholder='Search...'
                onChange={(e) => {
                  filtraReporte(e)
                }}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row>
        <Col xs={2}></Col>
        <Col xs={8}></Col>
        <Col xs={2} className='totalLabel'></Col>
      </Row>
      <Card>
        <Card.Body>
          <div className='MDcontainer'>
            <Table className='MDTable' hover>
              <thead style={{ position: 'sticky', top: '0' }}>
                <tr>
                  <th style={{padding:'5px'}}></th>
                  <th style={{padding:'5px'}}>id</th>
                  <th style={{padding:'5px'}}>Delivery</th>
                  <th style={{padding:'5px'}}>Fecha</th>
                  <th style={{padding:'5px'}}>Factura</th>
                  <th style={{padding:'5px'}}>PO Cliente</th>
                  <th style={{padding:'5px'}}>Heineken</th>
                  <th style={{padding:'5px'}}>Destino</th>
                  <th style={{padding:'5px'}}>Moneda</th>
                  <th style={{padding:'5px'}}>Numero4</th>
                  <th style={{padding:'5px'}}>Numero5</th>
                  <th style={{padding:'5px'}}>Numero6</th>
                  <th style={{padding:'5px'}}>Unidad</th>
                  <th style={{padding:'5px'}}>Numero7</th>
                  <th style={{padding:'5px'}}>Numero8</th>
                  <th style={{padding:'5px'}}>Incoterm</th>
                  <th style={{padding:'5px'}}>Incoterm2</th>
                  <th style={{padding:'5px'}}>Pedido SAP</th>
                  <th style={{padding:'5px'}}>Transportista</th>
                  <th style={{padding:'5px'}}>Caja</th>
                  <th style={{padding:'5px'}}>Bultos</th>
                  <th style={{padding:'5px'}}>Numero12</th>
                  <th style={{padding:'5px'}}>Trafico</th>
                  <th style={{padding:'5px'}}>Fecha de Cruce</th>
                  <th style={{padding:'5px'}}>Semaforo</th>
                </tr>
              </thead>
              <tbody>
                {Data
                  ? Data.map((MasterData) => {
                      return (
                        <>
                          <tr
                            style={{ cursor: 'pointer' }}
                            className={
                              MasterData.max === true
                                ? 'masterSelected'
                                : 'normalSelected'
                            }
                          >
                            <td
                              style={{ textAlign: 'left', padding:'5px'}}
                              className={
                                MasterData.max === true
                                  ? 'masterSelected'
                                  : 'normalSelected'
                              }
                              key={MasterData.id}
                              onClick={() => {
                                changeToggle(MasterData)
                              }}
                            >
                              {MasterData.max === true ? (
                                <IconContext.Provider
                                  value={{ color: 'blue', size: '15px' }}
                                >
                                  <BsChevronDown />
                                </IconContext.Provider>
                              ) : (
                                <IconContext.Provider
                                  value={{ color: 'blue', size: '15px' }}
                                >
                                  <BsChevronRight />
                                </IconContext.Provider>
                              )}
                            </td>
                            <td style={{padding:'5px'}}>{MasterData.id}</td>
                            <td style={{padding:'5px'}}>{MasterData.delivery}</td>
                            <td style={{padding:'5px'}}>{MasterData.fecha.substring(0, 10)}</td>
                            <td style={{padding:'5px'}}>{MasterData.factura}</td>
                            <td style={{padding:'5px'}}>{MasterData.poCliente}</td>
                            <td style={{padding:'5px'}}>{MasterData.heineken}</td>
                            <td style={{padding:'5px'}}>{MasterData.destino}</td>
                            <td style={{padding:'5px'}}>{MasterData.moneda}</td>
                            <td style={{padding:'5px'}}>{MasterData.numero4}</td>
                            <td style={{padding:'5px'}}>{MasterData.numero5}</td>
                            <td style={{padding:'5px'}}>{MasterData.numero6}</td>
                            <td style={{padding:'5px'}}>{MasterData.unidad}</td>
                            <td style={{padding:'5px'}}>{MasterData.numero7}</td>
                            <td style={{padding:'5px'}}>{MasterData.numero8}</td>
                            <td style={{padding:'5px'}}>{MasterData.incoterm}</td>
                            <td style={{padding:'5px'}}>{MasterData.incoterm2}</td>
                            <td style={{padding:'5px'}}>{MasterData.pedidoSAP}</td>
                            <td style={{padding:'5px'}}>{MasterData.transportista}</td>
                            <td style={{padding:'5px'}}>{MasterData.caja}</td>
                            <td style={{padding:'5px'}}>{MasterData.bultos}</td>
                            <td style={{padding:'5px'}}>{MasterData.numero12}</td>
                            <td style={{padding:'5px'}}>{MasterData.trafico}</td>
                            <td style={{padding:'5px'}}>{MasterData.fechaCruce ? MasterData.fechaCruce.substring(0,10) + " " + MasterData.fechaCruce.substring(11,20) : ''}</td>
                            { MasterData.semaforo ? 
                              <td style={{padding:'5px'}}>{MasterData.semaforo.toUpperCase() === 'V' ? 
                                <div style={{width: '100%', textAlign:"center"}}><BsCircleFill style={{color:'green'}}/></div> :
                                <div style={{width: '100%', textAlign:"center"}}><BsCircleFill style={{color:'red'}}/></div>}
                              </td> : <td></td>
                            }
                          </tr>
                          {MasterData.max ? (
                            <tr
                              className={
                                MasterData.max === true
                                  ? 'masterSelected2'
                                  : 'normalSelected2'
                              }
                            >
                              <th colSpan={10}>
                                <table className='childTable'>
                                  <thead>
                                    <tr>
                                      <th
                                        style={{
                                          width: '50px',
                                          backgroundColor: '#FFFFFF',
                                          color: '#346288',
                                        }}
                                      ></th>
                                      <th
                                        style={{
                                          width: '50px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                        }}
                                      >
                                        id
                                      </th>
                                      <th
                                        style={{
                                          width: '50px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                        }}
                                      >
                                        SeisDigitos
                                      </th>
                                      <th
                                        style={{
                                          width: '150px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                        }}
                                      >
                                        VeinteDigitos
                                      </th>
                                      <th
                                        style={{
                                          width: '250px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                        }}
                                      >
                                        Descripcion
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                        }}
                                      >
                                        Medida
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                        }}
                                      >
                                        Num1
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                        }}
                                      >
                                        Num2
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                        }}
                                      >
                                        HNumero4
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                        }}
                                      >
                                        Num3
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                        }}
                                      >
                                        HNumero6
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                        }}
                                      >
                                        Unidad
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                        }}
                                      >
                                        HNumero7
                                      </th>
                                      <th
                                        style={{
                                          width: '70px',
                                          backgroundColor: '#D3E0EA',
                                          color: '#346288',
                                        }}
                                      >
                                        HNumero8
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Data.map((MD) => {
                                      return (
                                        <>
                                          {MD.detail
                                            .filter(
                                              (detail) =>
                                                MasterData.id ===
                                                detail.idHeader
                                            )
                                            .map((detail) => {
                                              return (
                                                <tr>
                                                  <th
                                                    style={{
                                                      width: '50px',
                                                      backgroundColor:
                                                        '#FFFFFF',
                                                    }}
                                                    className='DetailData'
                                                  >
                                                    &nbsp;
                                                  </th>
                                                  <td
                                                    style={{
                                                      paddingLeft: '10px',
                                                    }}
                                                    className='DetailData'
                                                  >
                                                    {detail.id}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: 'center',
                                                    }}
                                                    className='DetailData'
                                                  >
                                                    {detail.seisDigitos}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: 'center',
                                                    }}
                                                    className='DetailData'
                                                  >
                                                    {detail.veinteDigitos}
                                                  </td>
                                                  <td className='DetailData'>
                                                    {detail.descripcion}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: 'center',
                                                    }}
                                                    className='DetailData'
                                                  >
                                                    {detail.medida}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: 'right',
                                                    }}
                                                    className='DetailData'
                                                  >
                                                    {detail.num1}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: 'right',
                                                    }}
                                                    className='DetailData'
                                                  >
                                                    {detail.num2}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: 'right',
                                                    }}
                                                    className='DetailData'
                                                  >
                                                    {detail.hNumero4}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: 'right',
                                                    }}
                                                    className='DetailData'
                                                  >
                                                    {detail.num3}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: 'right',
                                                    }}
                                                    className='DetailData'
                                                  >
                                                    {detail.hNumero6}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: 'center',
                                                    }}
                                                    className='DetailData'
                                                  >
                                                    {detail.unidad}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: 'right',
                                                    }}
                                                    className='DetailData'
                                                  >
                                                    {detail.hNumero7}
                                                  </td>
                                                  <td
                                                    style={{
                                                      textAlign: 'right',
                                                    }}
                                                    className='DetailData'
                                                  >
                                                    {detail.hNumero8}
                                                  </td>
                                                </tr>
                                              )
                                            })}
                                        </>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </th>
                            </tr>
                          ) : null}
                        </>
                      )
                    })
                  : null}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}
