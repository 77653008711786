import React, { useEffect, useState } from 'react'
import ItemMenu from './Interfaces/Catalogos/IItemMenu'
import { Outlet, useNavigate } from 'react-router-dom'
import { logued, setPerfil, setUserId } from './store/features/userStatusSlice/userStatusSlice'
import { Card, Col, Container, Form, Nav, Navbar, NavDropdown, Offcanvas, Row, Spinner } from 'react-bootstrap'
import usuariosServices from './Services/Catalogos/Usuarios.Services'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from './store/store'
import { IconContext } from 'react-icons'
import { BsPersonCircle } from 'react-icons/bs'
import logo from './images/largeLogo.png'
import './App.css'
import Submenu from './Components/Submenu/Submenu'
import "react-datepicker/dist/react-datepicker.css";
import { FaBell, FaTrash } from 'react-icons/fa6'
import { DTONotificacionesTransportista } from './Components/Cliente/DTO/DTONotificaciones'
import NotificacionesTransportistaService from './Services/NotificacionesTransportistaService'
import { AxiosError } from 'axios'

function App() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let _menu: ItemMenu[] = []
  const [mainMenu, setMainMenu] = useState(_menu)
  const userLogued = useSelector((state: RootState) => state.userStatus.value)
  const Perfil = useSelector((state: RootState) => state.userStatus.perfil)
  const UserId = useSelector((state: RootState) => state.userStatus.id)
  const [show, setShow] = useState(false)
  const [msg, setMsg] = useState('')
  const [header, setHeader] = useState('')
 // const msgColor = 'primary'
  const menu = localStorage.getItem('menu') ? localStorage.getItem('menu') : ''
  /* const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  }) */
  /* const [Perfil, setPerfil] = useState(() => {
    const stickyValue = window.localStorage.getItem('Perfil')
    return stickyValue !== null ? JSON.parse(stickyValue) : ''
  }) */
  const [Depto, setDepto] = useState(() => {
    const stickyValue = window.localStorage.getItem('Departamento')
    return stickyValue !== null
      ? JSON.parse(stickyValue).substring(0, 4) + '.'
      : ''
  })
  const [showNotificaciones, setShowNotificaciones] = useState(false)
  const [notificaciones, setNotificaciones] = useState<DTONotificacionesTransportista[]>([])
  const [IntervalId, setIntervalId] = useState<NodeJS.Timeout>() 
  useEffect(() => {
    if (menu && userLogued) {
      setMainMenu(JSON.parse(menu))
    } else {
      setMainMenu([])
    }
  }, [menu, userLogued])

  /* useEffect(() => {
    navigate('/')
  }, []) */

  useEffect(() => {
    getNotificaciones();
  }, [Perfil])

  useEffect(() => {
    usuariosServices
      .validate()
      .then((response) => {
        if (response.status === 200) {
          dispatch(logued(true))
          var perfil = window.localStorage.getItem('Perfil') !== null ? JSON.parse(window.localStorage.getItem('Perfil')!) : ''
          var userId = window.localStorage.getItem('UserId') !== null ? JSON.parse(window.localStorage.getItem('UserId')!) : ''
          dispatch(setPerfil(perfil))
          dispatch(setUserId(+userId))
        }
      })
      .catch((e: Error) => {
        if (!window.location.href.indexOf('login')) {
          localStorage.setItem('menu', '')
          localStorage.setItem('token', '')
          setHeader('Informativo')
          setMsg('Su session ha expirado, necesita volver a loguerse')
          setShow(true)
          navigate('/')
        }
      })
  }, [dispatch, navigate])

  const handleClose = () => setShowNotificaciones(false);
  const handleShow = () => setShowNotificaciones(true);

  useEffect(()=>{
    if(userLogued && Perfil==='Transportista'){
      let intervalId = setInterval(() => {
        getNotificaciones()
      },60000)
      setIntervalId(intervalId);
    }
  },[userLogued])

  const getNotificaciones = ()=>{
      NotificacionesTransportistaService.GetNotificaciones(UserId)
      .then(resp => setNotificaciones(resp.data))
      .catch((err:AxiosError) =>{
        console.log(err.request.responseText)
      })
    clearInterval(IntervalId);
  }

  const markAsRead = (id:number) => {
    NotificacionesTransportistaService.MarkAsRead(id)
      .then(resp => setNotificaciones(resp.data))
      .catch((err:AxiosError) =>{
        console.log(err.request.responseText)
      })
  }

  return (
    <div className='App'>
      <Navbar className='navBarColor'
        expand='lg'>
        <Container>
          <Navbar.Brand style={{ color: '#FFFFFF' }}>
            <img
              onClick={()=>navigate('/')}
              src={logo}
              alt='Heineken'
              style={{ width: '70%', cursor:'pointer' }}
            ></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='me-auto'>
              {mainMenu
                ? mainMenu.map((itemMenu, index) => {
                    if (itemMenu.padreId === 0) {
                      return (
                        <>
                          <Submenu
                            descripcion={itemMenu.descripcion}
                            key={itemMenu.id}
                            allItems={mainMenu}
                            submenu={mainMenu.filter(function (item) {
                              return item.padreId === itemMenu.id
                            })}
                          />
                        </>
                      )
                    }
                  })
                : ''}
            </Nav>
            <Nav>
              <Form className='d-flex' style={{ color: '#ffffff', alignItems:'center'}}>
                {!userLogued ? (
                  <>
                    <Nav.Link style={{ color: '#ffffff' }} href='/#/login'>
                      Login
                    </Nav.Link>
                  </>
                ) : (
                  <>
                    {/* <Nav.Link  style={{ color: '#ffffff' }} href='/#/help'>Help</Nav.Link> */}
                    {Perfil === 'Transportista' ? 
                      <>
                        <IconContext.Provider value={{size:'30px', color: '#ffffff', style:{paddingRight:'15px'}, className:'notification-container'}}>  
                          {notificaciones.length > 0 ? <Spinner animation='grow' variant='success' style={{position:'relative', left:'25px'}}/> : ''}
                          <FaBell className={notificaciones.length > 0 ? 'notification-icon' : ''} style={{cursor:'pointer'}} onClick={handleShow}/>
                            <span className='notification-counter'>
                                {notificaciones.length}
                            </span>
                        </IconContext.Provider>
                      </> : 
                      ''
                    }
                    <Nav.Link style={{ color: '#ffffff' }} href='/#/logout'>
                      
                      <IconContext.Provider value={{ size: '25px' }}>
                        Logout&nbsp;<BsPersonCircle />&nbsp;
                      </IconContext.Provider>
                    </Nav.Link>
                    <div
                      style={{ paddingTop: '5px', paddingLeft: '20px' }}
                    ></div>
                    
                  </>
                )}
              </Form>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Offcanvas show={showNotificaciones} onHide={handleClose} placement='end'>
        <Offcanvas.Header className='navBarColor' closeButton closeVariant='white'>
          <Offcanvas.Title style={{color:'#fff'}}>Notificaciones</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {notificaciones && notificaciones.map(n => (
            <Card key={n.id} className='mb-3'>
              <Card.Header style={{textAlign:'right'}}><span>{n.fechaHoraNotificacion}</span></Card.Header>
              <Card.Body>
                <Row>
                  <Col xs={10}>{n.mensaje}</Col>
                  <Col xs={2} style={{alignSelf:'center'}}>
                    <IconContext.Provider value={{ color:'red' }}>
                        <FaTrash style={{cursor:'pointer'}} onClick={() => {markAsRead(n.id)}}/>
                    </IconContext.Provider>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          ))}
        </Offcanvas.Body>
      </Offcanvas>
      <Outlet />
    </div>
  )
}

export default App
